@import "../../Layout/flexbox.scss";
@import "../../Layout/template.scss";
@import "../../Layout/_templateVariables.scss";
@import "../../Layout/_templateFramework.scss";
@import "../../Layout/_templateLayout.scss";

.shiftPlanMain1 {
  background: white;
  padding-top: 40px;
  .shiftPlan1Wrapper {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1200px) {
      justify-content: center;
    }
    .autoWidthBox {
      display: flex;
      flex-grow: 1;

      @media (max-width: 1200px) {
        justify-content: center;
      }
    }
  }
}

.shiftPlanImage1 {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  width: 100%;
  @media (max-width: 1200px) {
    justify-content: center;
  }
  @media (max-width: 400px) {
    max-width: 100%;
  }
  .openShiftImg {
    // box-shadow: 0px 1px 6px 1px #e2e2e2;
    // b
    // b
    width: 100%;
    max-width: 500px;
  }
}

.shiftPlanMain1 {
  .funcWrapDiscription {
    @media (max-width: 1200px) {
      text-align: center;
    }
  }

  .shiftplan1TextWrapper {
    padding-left: 0px;

    display: flex;
    justify-content: center;
    text-align: left;
    align-items: flex-start;
    @media (max-width: 1200px) {
    }
    @media (max-width: 450px) {
      padding-right: 0px !important;
    }
    .shiftplanDiscriptionWrapper {
      width: 100%;
      @media (max-width: 1200px) {
        max-width: 90%;
      }
    }

    .shiftPlanHeadWrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 20px;

      .headWrapperVacation {
        justify-content: flex-start;
        width: 100%;
        display: flex;
        font-weight: 600;
        font-size: 30px;
        font-family: "Poppins";
        text-align: left;
        line-height: 32px;
        color: #353c42;
        @media (max-width: 991px) {
          text-align: center;
          justify-content: center;
        }
      }

      .headerIcon {
        width: 34px;
        margin-right: 20px;
      }
    }
  }

  .shiftplan1TextWrapper {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    width: 100%;
    @media (max-width: 1300px) {
      // margin-left: 30px !important;
    }

    @media (max-width: 1200px) {
      text-align: center;
      align-items: center;
      padding-left: 0px;
      padding-top: 60px;
    }
  }
}

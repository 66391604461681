@import "../../Layout/flexbox.scss";
@import "../../Layout/template.scss";
@import "../../Layout/_templateVariables.scss";
@import "../../Layout/_templateFramework.scss";
@import "../../Layout/_templateLayout.scss";

.shiftPlan2Main {
  background: white;
  justify-content: center;
  display: flex;

  .shiftPlan2Wrapper {
    justify-content: space-between;
    width: 100%;
    padding-top: 40px;
    @media (max-width: 1200px) {
      justify-content: center;
    }
    .shiftPlan2Imagewrapper {
      order: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1200px) {
        justify-content: center;
        order: 1;
      }
      .shiftPlanImage2 {
        width: 100%;
        max-width: 440px;
        // @media (max-width: 1200px) {
        //   order: 1;
        // }
        @media (max-width: 400px) {
          max-width: 100%;
        }
      }
      .shiftAssignImg {
        // box-shadow: 0px 1px 6px 1px #e2e2e2;
        // border: 1px solid #e2e2e2;
        // border-radius: 4px;
      }
    }
  }
}

.shiftPlan2TextWrapper {
  padding-left: 0px;
  order: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 18px;
  @media (max-width: 1200px) {
    text-align: center;
    order: 2;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 450px) {
    padding-right: 0px !important;
  }
  .reportingBlockHeadWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    @media (max-width: 1200px) {
      justify-content: center;
    }

    .headWrapperMobile {
      justify-content: flex-start;
      width: 100%;
      display: flex;
      font-weight: 600;
      font-size: 30px;
      font-family: "Poppins";
      text-align: left;
      line-height: 32px;
      color: #353c42;
      @media (max-width: 991px) {
        text-align: center;
        justify-content: center;
      }
    }

    .headerIcon {
      width: 34px;
      margin-right: 20px;
    }
  }
}

.reportingBlockDiscriptionWrapper {
  display: flex;
  justify-content: center;
  @media (max-width: 1200px) {
    max-width: 90%;
  }
}

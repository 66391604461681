#dragDropVid {
  width: 700px;
  height: 420px;

  @media (max-width: 1200px) {
    width: 600px;
    height: 360px;
  }

  @media (max-width: 640px) {
    width: 100% !important;
    height: auto !important;
  }
}

.dummyImg {
  width: 700px !important;
  height: 420px !important;

  @media (max-width: 1200px) {
    width: 600px !important;
    height: 360px !important;
  }

  @media (max-width: 640px) {
    width: 100% !important;
    height: auto !important;
  }
}

.vidItemLeftBlockMain {
  display: flex;
  padding: 80px 0;
  justify-content: center;
  @media (max-width: 440px) {
    display: none;
  }

  .vidItemWrapper {
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  .vidItemImageWrapper {
    @media (max-width: 991px) {
      display: flex;
      justify-content: center;
    }
    .leftBlockImg {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    // .leftBlockImg {
    //   @media (max-width: 1200px) {
    //     width: 100% !important;
    //   }
    // }
  }

  .vidItemImageDescription {
    padding-left: 60px;
    @media (max-width: 991px) {
      padding-top: 40px;
      padding-left: 0px;
    }
    .vidItemBlockTitle {
      justify-content: flex-start;
      width: 100%;
      display: flex;
      font-weight: 600;
      margin-bottom: 30px;
      font-size: 30px;
      font-family: "Poppins";
      text-align: left;
      line-height: 32px;
      color: #353c42;

      @media (max-width: 991px) {
        text-align: center;
        justify-content: center;
      }
    }
    .vidItemBlockText {
      font-size: 20px;
      padding-bottom: 20px;
      text-align: left;
      color: rgb(89, 89, 89);
      @media (max-width: 991px) {
        text-align: center;
      }
    }
  }
}

.bottomFooterWrapper {
  background: white;
  .trustpilotFooterSeal {
    width: 220px;
    padding-top: 20px;
    padding-bottom: 20px;
    // @media (max-width: 490px) {
    //   display: none;
    // }
  }

  .bottomFooterLinkRow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    flex-wrap: wrap;

    .bottomFooterLink {
      color: rgb(140, 140, 140);
      &:hover {
        color: #1a90ff;
      }
    }
  }
}

@import "../Layout/flexbox.scss";
@import "../Layout/template.scss";
@import "../Layout/_templateVariables.scss";
@import "../Layout/_templateFramework.scss";
@import "../Layout/_templateLayout.scss";

.getStarted {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1a90ff;

  // @media (max-width: 991px) {
  //   padding-bottom: 50px;
  // }

  .getStartedWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    // justify-content: center;
    @media (max-width: 991px) {
      justify-content: center;
    }

    .getStartedTextWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .customH2 {
        color: white;
        text-align: left;
        margin-bottom: 40px;
        font-weight: 700;
        font-size: 60px;
        line-height: 53px;
      }

      .actionWrapper {
        padding: 30px;
        border-radius: 6px;
        background: #177bd8;

        // @media (max-width: 490px) {
        border-radius: 6px;
        // }
        @media (max-width: 600px) {
          border-radius: 0px;
        }
      }

      .secondaryText {
        opacity: 0.8;
        font-size: 24px;
        color: white;
        margin-top: 0px;
        text-align: left;
        margin-bottom: 20px;
        line-height: 30px;
        @media (max-width: 400px) {
          // text-align: center;
        }
      }

      .actionFieldWrapper {
        display: flex;
        @media (max-width: 600px) {
          flex-direction: column;
        }
        .inputCustomizedGetStarted {
          background: white;
          display: flex;
          flex-grow: 2;
          @media (max-width: 600px) {
            width: 100%;
            text-align: center;
            // text-align: center;
          }
        }

        .buttonCustomizedGetStartedNew {
          // background-color: #12adf3;
          background-color: #fd5aa7;
          color: #ffffff;
          display: flex;
          flex-grow: 1;
          font-weight: 600;
          justify-content: center;
          @media (max-width: 600px) {
            width: 100%;
            margin-top: 10px;
            text-align: center;
          }
          &:hover {
            background-color: #fd5aa7;
            opacity: 0.8 !important;
          }
        }
      }

      .alrdyMemberText {
        padding-top: 16px;
        color: white;
        text-align: left;
        .loginLinkGetStarted {
          color: white;
          text-decoration: underline;
        }
      }
    }
    .getStartedImgWrapper {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 991px) {
        display: none;
      }

      .getStartedImg {
        width: 500px;
      }
    }
  }
}

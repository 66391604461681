svg {
  transition: 0.1s ease;
  .rosterIcon {
    fill: grey;
  }

  .active {
    fill: white;
  }
}

.viewIcon {
  width: 20px;
  height: 20px;
  transition: 0.1s ease;
}

.highlight {
  transition: 0.1s ease;
  transform: scale(1.2);
}

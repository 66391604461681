@import "../Layout/flexbox.scss";
@import "../Layout/template.scss";
@import "../Layout/_templateVariables.scss";
@import "../Layout/_templateFramework.scss";
@import "../Layout/_templateLayout.scss";

.shiftplanViewRotatorMain {
  .viewRotatorContainer {
    // background: url(../../../../static/i/bg-faceless-wave-2.svg) no-repeat
    //   center center;
    display: flex;
    background-size: cover;
    flex-direction: row;
    padding-top: 60px;
    padding-bottom: 100px;

    @media (max-width: 500px) {
      padding-bottom: 30px;
    }

    @media screen and (min-width: 1200px) {
      flex-direction: row;
      // .backgroundImageWrapper {
      //   max-width: 991px;
      // }
    }

    .rotationWrapper {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1200px) {
        justify-content: center;
        flex-direction: column;
      }
      @media (min-width: 576px) {
        max-width: auto;
      }
    }
    .rotationHeader {
      justify-content: flex-start;
      width: 100%;
      display: flex;
      font-weight: 600;
      margin-bottom: 30px;
      font-size: 30px;
      font-family: "Poppins";
      text-align: left;
      line-height: 32px;
      color: #353c42;
      @media (max-width: 1200px) {
        justify-content: center;
        text-align: center;
        max-width: 98%;
      }
    }
    .rotationDescription {
      display: flex;
      justify-content: flex-start;
      text-align: left;
      font-size: 22px;
      color: grey;
      margin-bottom: 50px;
      // max-width: 60%;
      @media (max-width: 1200px) {
        justify-content: center;
        text-align: center;
        max-width: 98%;
      }
    }
  }

  .visible {
    animation: fadeIn 500ms ease;
  }

  .invisible {
    animation: fadeOut 500ms ease;
  }

  .backgroundEmptyImageContainer {
    background: #f5f5f5;
  }

  .backgroundImageWrapper {
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    margin-right: 30px;
    display: flex;
    // z-index: 500;
    align-items: flex-start;
    justify-content: center;
    // border-radius: 4px;
    overflow: hidden;
    padding: 0;
    box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.06);
    width: 94%;
    @media (max-width: 1200px) {
      width: 100% !important;
    }

    .backgroundImage {
      width: 100%;
      height: auto;
    }
  }

  @keyframes fadeOut {
    0% {
      transform: translate3d(0, 0px, 0);
      visibility: visible;
      opacity: 1;
    }
    50% {
      transform: translate3d(0, 0px, 0);
      opacity: 0.5;
      visibility: visible;
    }
    100% {
      transform: translate3d(0, -3px, 0);
      visibility: hidden;
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      transform: translate3d(0, -3px, 0);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      transform: translate3d(0, 0px, 0);
      opacity: 1;
      visibility: visible;
    }
  }

  .inputCustomized {
    background-color: #ffffff;
    border-color: #ffffff;
    border: 2px solid #fff;
    max-width: 260px;
    @media (max-width: 490px) {
      text-align: center;
      margin-right: 0px !important;
      width: 100%;
      max-width: 100% !important;
    }
  }

  .buttonCustomized {
    width: 100%;
    @media (max-width: 490px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  .inputCustomized:focus {
    color: #108aec;
    border-color: #108aec;
    background: 0 0;
    opacity: 1;
  }

  .rotationButtonContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    width: 32%;
    @media (max-width: 1200px) {
      width: auto !important;
      flex-wrap: wrap;
      margin: auto;
      justify-content: center;
      flex-direction: row;
    }

    .viewButtonWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      width: 80%;
      @media (max-width: 1200px) {
        width: auto;
      }
      @media (max-width: 460px) {
        width: 100%;
      }
      .shiftplanViewItem {
        // width: 78%;
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        background: whitesmoke;
        color: grey;
        cursor: pointer;
        min-width: 220px;
        border-radius: 4px;
        transition: 0.2s ease;
        box-shadow: inset 0px 0px 8px 1px #efefef;
        @media (max-width: 460px) {
          width: 100%;
        }
        @media (max-width: 1200px) {
          margin-left: 4px;
          margin-right: 4px;
        }

        .viewIconWrapper {
          min-width: 30px;
          min-height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.2s ease;
        }

        .shiftplanViewItemName {
          margin-left: 14px;
          font-size: 20px;
          font-weight: 500;
        }
        &:hover {
          background: #19a0ff;
          box-shadow: none;
          .shiftplanViewItemName {
            color: white;
          }
          .viewIcon {
            g {
              fill: white;
            }
          }
        }
      }

      .connectingLine {
        height: 2px;
        width: 0px;
        background: #19a0ff;
        transition: 0.2s ease;
      }
      .activeLine {
        width: 30% !important;
      }

      .activeItem {
        color: white;
        background: #19a0ff;
        box-shadow: inset 0 0 5px 1px #2898e8;
      }
    }
  }
}

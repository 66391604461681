@import "../Layout/flexbox.scss";
@import "../Layout/template.scss";
@import "../Layout/_templateVariables.scss";
@import "../Layout/_templateFramework.scss";
@import "../Layout/_templateLayout.scss";

.featureHeadLine {
  @media (max-width: 991px) {
    font-size: 28px;
    width: 100%;
  }
}

.featureHeadLine {
  @media (max-width: 770px) {
    font-size: 24px;
    padding-bottom: 0px;
  }
}

.mainFeatureWrapperLanding {
  display: flex;
  background: #fbfbfb;
  padding: 80px 0 80px 0;
  @media (max-width: 490px) {
    display: none;
  }
  @media (max-width: 1200px) {
    padding-bottom: 20px;
  }

  .featurePaperLeft {
    padding: 0 30px 0 0px !important;
  }

  .featurePaperMiddle {
    padding: 0 30px 0 30px !important;
  }

  .featurePaperRight {
    padding: 0 0px 0 30px !important;
  }
  .featurePaper {
    border-radius: 4px;
    margin-bottom: 18px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    @media (max-width: 1200px) {
      padding: 0 !important;
      align-items: center;
      margin-bottom: 50px;
    }

    .featureHeaderWrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 22px;
      align-items: center;
      .discriptionHead {
        font-weight: 700;
        color: #1690ff;
        font-family: "Poppins";
        text-align: center;
        font-size: 28px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 10px;
        margin: 0 !important;
        // @media (max-width: 1270px) {
        //   font-size: 18px;
        // }
      }
      .discriptionWrapper {
        max-width: 320px;
        text-align: center;
        font-size: 16px;
        line-height: 1.8;
        display: block;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-height: 230px;
        text-align: center !important;
        @media (max-width: 1200px) {
          max-width: 600px;
        }

        @media (max-width: 800px) {
          min-height: 200px;
        }
      }
    }
    .featureLinkTag {
      color: white;

      .discriptionLink {
        transition: 0.2s ease;
        background: #1690ff;
        border-radius: 4px;
        padding: 6px 20px 6px 20px;
        @media (max-width: 1200px) {
          margin-bottom: 30px;
        }
        &:hover {
          background: #117fce;
        }
      }
    }
  }

  .featureHeadLine {
    color: #54626d;
    width: 80%;
  }

  .customFeatureWrapper {
    justify-content: space-between;
    align-items: flex-start;
    margin: auto;
    padding: 0 !important;
    @media (max-width: 1200px) {
      justify-content: center;
    }
  }
}

.featureWrapper {
  display: flex;
  justify-content: center;
  padding: 40px;
  // width: 100%;
  // height: 220px;
}

.mainFeatureWrapperLanding {
  .customFeatureWrapper {
    .discriptionWrapper {
      @media (max-width: 1200px) {
        max-width: 600px;
        font-size: 18px;
        text-align: center !important;
      }
      @media (max-width: 660px) {
        max-width: 90%;
      }
    }
  }
}

// .discriptionHead {
//   @media (max-width: 1270px) {
//     font-size: 18px;
//   }
// }
.customTextWrapper {
  background-size: 100%;
  .customPaper {
    .customText {
      font-size: 30px;
      line-height: 40px;
      font-weight: 00;
      color: #596771;
      width: 80%;
      // font-family: DM sans;

      @media (max-width: 1200px) {
        font-size: 36px;
      }
      @media (max-width: 990px) {
        font-size: 30px;
      }
      @media (max-width: 600px) {
        font-size: 24px;
      }
    }
  }
}

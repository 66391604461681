.startFreeLinkButton {
  width: fit-content;
  .startLinkButton {
    &:hover {
      .linkTextWrapper {
        padding-right: 6px;
      }
    }

    .linkTextWrapper {
      transition: 0.2s ease;
      color: #19a0ff !important;
    }
    @media (max-width: 991px) {
      justify-content: center;
    }
  }

  &:hover {
    color: #19a0ff !important;
  }
}
